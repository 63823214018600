<template>
	<div>
		<multiselect
			:placeholder="$t('select')"
			v-model="selected"
			:options="options"
			:class="validateError != '' ? 'box-border-color':''"
			@input="handleInput($event)"
			label="text"
			track-by="value"
			:multiple="false"
			:select-label="''"
			:selected-label="''"
			:deselect-label="''"
            :disabled="disabled"
			:searchable="true">
            <span slot="noOptions">
                {{ $t('no_options') }}
            </span>
			<span slot="noResult">
                {{ $t('no_result') }}
            </span>
		</multiselect>
        <span class="input-desc" v-if="description">{{description}}</span>
		<span class="invalid-feedback-custom" v-show="validateError" v-html="validateError"/>
	</div>
</template>

<script>
	// Helpers
	import handleInput from "@/helpers/handleInput";
	import setSelected from "@/helpers/setSelected";

	export default {
		props: {
			value: {
				default: null
			},
			validateError: {
				type: String,
				default: ''
			},
            description: {
                type: String,
                default: ''
            },
            disabled: {
                type: Boolean,
                default: false
            }
		},
		watch: {
			value: function (newValue) {
				this.selected = this.setSelected(newValue, this.options)
			},
		},
		data() {
			return {
				selected: null,
				options: [
					{
						value: 'Yes',
						text: this.$t('yes'),
					},
					{
						value: 'No',
						text: this.$t('no'),
					}
				]
			}
		},
		created() {
			this.selected = this.setSelected(this.value, this.options)
		},
		methods: {
			handleInput: handleInput,
			setSelected: setSelected,
		}
	}
</script>
<style scoped>
.input-desc {
    font-size:10px;
    color:#7e878f;
}
</style>
